/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import AdvancedSearchQueryBuilder from '@broadleaf/admin-components/dist/collection/components/AdvancedSearchQueryBuilder';
import ActionListGridQueryFilter from '@broadleaf/admin-components/dist/collection/components/ActionListGrid/action-components/ActionListGridQueryFilter';

export default [
  {
    component: AdvancedSearchQueryBuilder,
    placement: 'ADVANCED_FILTER',
    type: 'QUERY_BUILDER'
  },
  {
    component: ActionListGridQueryFilter,
    placement: 'QUERY_FILTER',
    type: 'QUERY'
  }
];
