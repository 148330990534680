/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import EntityNav from '@broadleaf/admin-components/dist/view/components/EntityNav';
import SandboxRibbon from '@broadleaf/admin-components/dist/tracking/components/SandboxRibbon';
import {
  getCurrentView,
  setCurrentView
} from '@broadleaf/admin-components/dist/view/utils/ViewUtils';

import ReadyToSubmitGrid from './components/ReadyToSubmitGrid';
// import InvoiceSubmissionHistoryGrid from './components/InvoiceSubmissionHistoryGrid';
// import WaitingForApprovalGrid from './components/WaitingForApprovalGrid';
import messages from './InvoicesView.messages';
import { History, Location } from 'history';
import { InvoiceSubmissionHistoryGrid } from './components/InvoiceSubmissionHistoryGrid';
import { OrderProcessingGrid } from './components/OrderProcessingGrid';
import { OtherInvoicesGrid } from './components/OtherInvoicesGrid';

/**
 * This is a view for rendering the list grids dealing with a Users
 * ChangeSummaries and ChangeSummaryGroups.
 * It utilizes the ViewUtils to manage which grid is rendered based on the
 * view parameter of the location search string.
 */
export const InvoicesView: React.FC<InvoicesViewProps> = ({
  location,
  history,
  views
}) => {
  const formatMessage = useFormatMessage();
  const currentView = getCurrentView(views, location);
  const { component: View } = currentView;

  if (!currentView) {
    return null;
  }

  return (
    <div className="tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-bg-gray-100">
      <div className="tw-min-h-16 tw-flex tw-h-16 tw-w-full tw-flex-row tw-flex-nowrap tw-items-center tw-justify-end tw-border-b tw-border-t tw-border-b-gray-200 tw-border-t-gray-200 tw-bg-white tw-px-4 tw-py-2 tw-shadow">
        <div className="tw-flex tw-flex-1 tw-flex-row tw-flex-nowrap tw-items-center tw-overflow-visible tw-whitespace-nowrap tw-text-xl">
          <span className="font-weight-normal">
            {formatMessage(messages.title)}
          </span>
          <span className="p-2">{'/'}</span>
          <span className="font-weight-normal">{currentView.label}</span>
        </div>
      </div>
      <div className="tw-flex-auto tw-overflow-auto">
        <div className="tw-px-4 tw-py-6">
          <EntityNav
            items={views}
            onChange={view => setCurrentView(view, history, location)}
            value={currentView.value}
          />
          <div className="tw-relative tw-flex tw-min-w-0 tw-flex-col tw-break-words tw-border-none tw-bg-white tw-bg-clip-border tw-px-4 tw-py-5 tw-shadow">
            <View key={currentView.value} />
          </div>
        </div>
      </div>
    </div>
  );
};

InvoicesView.defaultProps = {
  /**
   * Our default sub-view configuration for this component. This will typically
   * be used for almost every application, but can be overridden by manually
   * rendering this view container with a new set of "views".
   *
   * @type {Array}
   */
  views: [
    {
      component: ReadyToSubmitGrid,
      label: <FormattedMessage {...messages.readyToSubmit} />,
      value: 'readyToSubmit'
    },
    {
      component: OrderProcessingGrid,
      label: <FormattedMessage {...messages.orderProcessing} />,
      value: 'orderProcessing'
    },
    {
      component: InvoiceSubmissionHistoryGrid,
      label: <FormattedMessage {...messages.invoiceSubmissionHistory} />,
      value: 'invoiceSubmissionHistory'
    },
    {
      component: OtherInvoicesGrid,
      label: <FormattedMessage {...messages.otherInvoices} />,
      value: 'other'
    }
  ]
};

export interface InvoicesViewProps {
  /** history provided by react router */
  history: History;
  /** location provided by react router */
  location: Location;
  /** The set of sub-views that we use to render the menu and body of this view. */
  views: Array<{
    /** the component that will be rendered for this sub-view **/
    component: Function;
    /**
     * label for this sub-view. Can be a {@link FormattedMessage} or a
     * pre-localized string.
     */
    label: string | React.ReactNode | Element;
    /** a key or value used as a unique identifier for this sub-view **/
    value: string;
  }>;
}

export default InvoicesView;
