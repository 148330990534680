/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
/**
 * This is where named components should be registered, for example:
 *
 * <code>
 * import { services } from '@broadleaf/admin-components';
 * const { ComponentRegistrar } = services;
 *
 * ComponentRegistrar.registerComponent('blButton', MyCustomButton);
 *
 * // override a Field type
 * ComponentRegistrar.registerFieldComponent('STRING', NewStringField);
 *
 * // add a new Field type
 * ComponentRegistrar.registerFieldComponent('CUSTOM', MyCustomField);
 *
 * // register a new View type
 * ComponentRegistrar.registerViewComponent('MY_VIEW', MyView);
 * </code>
 */
import CalculatedPriceField from './components/CalculatedPriceField';
import CalculatedVendorRefField from './components/CalculatedVendorRefField';
import CalculatedNetworkRefField from './components/CalculatedNetworkRefField';
import ProductInfoField from './components/ProductInfoField';
import ProductInfoDisplay from './components/ProductInfoDisplay';
import ScanditBarcodeScannerField from './components/form/barcode/ScanditBarcodeScannerField';
import BulkScanditBarcodeScannerField from './components/form/barcode/BulkScanditBarcodeScannerField';
import BulkScanResidentMapField from './components/form/BulkScanResidentMapField/BulkScanResidentMapField';
import bulkScanResidentMapActions from './components/form/BulkScanResidentMapField/action-components';
import ImpersonateRevMedUser from './components/form/EntityForm/actions/ImpersonateRevMedUser';
import BulkScanSummaryColumn from './components/form/column-types/BulkScanSummaryColumn';
import RawEnumColumn from './components/form/column-types/RawEnumColumn';
import LabeledBooleanField from './components/LabeledBooleanField';
import RmMoneyField from './components/RmMoneyField';
import RMEnumSwitchField from './components/form/toggle-switch/RMEnumSwitchField';
import LinkColumn from './components/LinkColumn';
import LookupField from './components/form/lookup/LookupField';
import Header from './components/view/Header';
import Navigation from './components/view/navigation/Navigation';
import RMButton from './components/RMButton';
import RMToggleSwitch from './components/form/toggle-switch/RMToggleSwitch';
import RmFulfillmentView from './oms/FulfillmentView/RmFulfillmentView';
import LinkButton from './components/form/EntityGrid/actions/LinkButton';
import InlineGroup from './components/form/group-types/InlineGroup';
import VariantIdentificationGroup from './components/form/group-types/VariantIdentificationGroup';
import { ComponentRegistrar } from '@broadleaf/admin-components/dist/common';
import { ActionRegistrar } from '@broadleaf/admin-components/dist/metadata';
import InvoicesView from './oms/InvoicesView';
import { ReadyToSubmitGrid } from './oms/InvoicesView/components/ReadyToSubmitGrid';
import { InvoiceSubmissionHistoryGrid } from './oms/InvoicesView/components/InvoiceSubmissionHistoryGrid';
import { OrderProcessingGrid } from './oms/InvoicesView/components/OrderProcessingGrid';
import { OtherInvoicesGrid } from './oms/InvoicesView/components/OtherInvoicesGrid';

// register group components
ComponentRegistrar.registerGroupComponent('INLINE', InlineGroup);
ComponentRegistrar.registerGroupComponent(
  'VARIANT_IDENTIFICATION',
  VariantIdentificationGroup
);

// Register the custom ProductInfo field
ComponentRegistrar.registerFieldComponent(
  'PRODUCT_INFO',
  ProductInfoField(ProductInfoDisplay)
);
// Register the custom CalculatedPrice field
ComponentRegistrar.registerFieldComponent(
  'CALCULATED_PRICE',
  CalculatedPriceField
);
ComponentRegistrar.registerFieldComponent(
  'CALCULATED_VENDOR_REF',
  CalculatedVendorRefField
);
ComponentRegistrar.registerFieldComponent(
  'CALCULATED_NETWORK_REF',
  CalculatedNetworkRefField
);

ComponentRegistrar.registerFieldComponent('MONEY', RmMoneyField);
ComponentRegistrar.registerFieldComponent('BOOLEAN', LabeledBooleanField);

ComponentRegistrar.registerFieldComponent('ENUM_SWITCH', RMEnumSwitchField);

ComponentRegistrar.registerColumnComponent('LINK', LinkColumn);

ComponentRegistrar.registerFieldComponent('LIST_PRODUCT_LOOKUP', LookupField);
// Register the barcode scanner field
ComponentRegistrar.registerFieldComponent(
  'QUAGGA_BARCODE_SCANNER_FIELD',
  ScanditBarcodeScannerField()
);
ComponentRegistrar.registerFieldComponent(
  'SCANDIT_BARCODE_SCANNER_FIELD',
  ScanditBarcodeScannerField()
);
ComponentRegistrar.registerFieldComponent(
  'BULK_SCANDIT_BARCODE_SCANNER_FIELD',
  BulkScanditBarcodeScannerField()
);
ComponentRegistrar.registerComponent('blHeader', Header);
ComponentRegistrar.registerComponent('blNavigation', Navigation);

ComponentRegistrar.registerComponent('blButton', RMButton);

ComponentRegistrar.registerComponent('blToggleSwitch', RMToggleSwitch);

ComponentRegistrar.registerViewComponent(
  RmFulfillmentView.TYPE,
  RmFulfillmentView
);

ActionRegistrar.registerActionComponents(
  'View',
  RmFulfillmentView.TYPE,
  RmFulfillmentView.actionComponents
);

ComponentRegistrar.registerViewComponent('INVOICES', InvoicesView);

ActionRegistrar.registerActionComponents(
  'View',
  ReadyToSubmitGrid.VIEW_TYPE,
  ReadyToSubmitGrid.actionComponents
);
ActionRegistrar.registerActionComponents(
  'View',
  InvoiceSubmissionHistoryGrid.VIEW_TYPE,
  InvoiceSubmissionHistoryGrid.actionComponents
);
ActionRegistrar.registerActionComponents(
  'View',
  OrderProcessingGrid.VIEW_TYPE,
  OrderProcessingGrid.actionComponents
);
ActionRegistrar.registerActionComponents(
  'View',
  OtherInvoicesGrid.VIEW_TYPE,
  OtherInvoicesGrid.actionComponents
);

ActionRegistrar.registerActionComponent('View', 'ENTITY_GRID', {
  placement: 'GRID',
  type: 'BULK_SCAN',
  component: LinkButton
});

ActionRegistrar.registerActionComponent('View', 'ENTITY', {
  placement: 'SECONDARY',
  type: 'IMPERSONATE_REVMED_USER',
  component: ImpersonateRevMedUser
});

ComponentRegistrar.registerFieldComponent(
  'BULK_SCAN_RESIDENT_MAP',
  BulkScanResidentMapField
);
ActionRegistrar.registerActionComponents(
  'Field',
  'BULK_SCAN_RESIDENT_MAP',
  bulkScanResidentMapActions
);

ComponentRegistrar.registerColumnComponent('RAW_ENUM', RawEnumColumn);
ComponentRegistrar.registerColumnComponent(
  'BULK_SCAN_SUMMARY',
  BulkScanSummaryColumn
);
