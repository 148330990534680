/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

const resting = defineMessages({
  submitted: {
    id: 'InvoiceGridUtils.statusOptions.resting.submitted',
    defaultMessage: 'Submitted',
    description: ''
  },
  rejected: {
    id: 'InvoiceGridUtils.statusOptions.resting.rejected',
    defaultMessage: 'Rejected',
    description: ''
  },
  reimbursed: {
    id: 'InvoiceGridUtils.statusOptions.resting.reimbursed',
    defaultMessage: 'Reimbursed',
    description: ''
  },
  invoicePaid: {
    id: 'InvoiceGridUtils.statusOptions.resting.invoicePaid',
    defaultMessage: 'Invoice Paid',
    description: ''
  },
  disputed: {
    id: 'InvoiceGridUtils.statusOptions.resting.disputed',
    defaultMessage: 'Disputed',
    description: ''
  }
});

const active = defineMessages({
  orderProcessing: {
    id: 'InvoiceGridUtils.statusOptions.active.orderProcessing',
    defaultMessage: 'Order Processing',
    description: ''
  },
  creditPending: {
    id: 'InvoiceGridUtils.statusOptions.active.creditPending',
    defaultMessage: 'Credit Pending',
    description: ''
  },
  readyToSubmit: {
    id: 'InvoiceGridUtils.statusOptions.active.readyToSubmit',
    defaultMessage: 'Ready to Submit',
    description: ''
  }
});

const error = defineMessages({
  holdForReview: {
    id: 'InvoiceGridUtils.statusOptions.active.holdForReview',
    defaultMessage: 'Hold for Review',
    description: ''
  }
});

const statusOptions = {
  resting,
  active,
  error
};

const headers = defineMessages({
  submitDate: {
    id: 'invoices.columns.submit-date',
    defaultMessage: 'Submit Date',
    description: ''
  },
  invoiceStatus: {
    id: 'invoices.columns.invoice-status',
    defaultMessage: 'Invoice Status',
    description: ''
  },
  invoiceStatusReason: {
    id: 'invoices.columns.invoice-status-reason',
    defaultMessage: 'Invoice Status Reason',
    description: ''
  },
  invoiceDate: {
    id: 'invoices.columns.invoice-date',
    defaultMessage: 'Invoice Date',
    description: ''
  },
  orderNumber: {
    id: 'invoices.columns.order-number',
    defaultMessage: 'Order Number',
    description: ''
  },
  invoiceNumber: {
    id: 'invoices.columns.invoice-number',
    defaultMessage: 'Invoice Number',
    description: ''
  },
  poNumber: {
    id: 'invoices.columns.po-number',
    defaultMessage: 'PO Number',
    description: ''
  },
  shipToOrg: {
    id: 'invoices.columns.ship-to-org',
    defaultMessage: 'Ship To Org',
    description: ''
  },
  invoiceTotal: {
    id: 'invoices.columns.invoice-total',
    defaultMessage: 'Invoice Total',
    description: ''
  },
  revMedCreditAmount: {
    id: 'invoices.columns.revmed-credit-amount',
    defaultMessage: 'RevMed Credit',
    description: ''
  }
});

export default {
  headers,
  statusOptions
};
