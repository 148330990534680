/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import { UserAccess } from '@broadleaf/admin-components/dist/authentication';
import { selectAllSelectedIds } from '@broadleaf/admin-components/dist/collection/hooks/useCollectionState';
import Button from '@broadleaf/admin-components/dist/common/elements/Button';
import useActionCreators from '@broadleaf/admin-components/dist/common/hooks/useActionCreators';
import { startPromote } from '@broadleaf/admin-components/dist/tracking/actions/sandboxOperation';
import SandboxOperationDispatchContext from '@broadleaf/admin-components/dist/tracking/contexts/SandboxOperationDispatchContext';
import type { IMetadataAction } from '@broadleaf/admin-components/dist/types/metadata';
import type { ICollectionStateHook } from '@broadleaf/admin-components/dist/types/collection';

export const SubmitInvoicesAction: React.FC<SubmitInvoicesActionProps> = props => {
  const checkAccess = UserAccess.useCheckAccess();
  const actions = useActionCreators(SandboxOperationDispatchContext, {
    startPromote
  });
  if (!checkAccess('USER_WORKFLOW', 'UPDATE')) {
    // must have access to promote
    return null;
  }

  const allSelectedIds = selectAllSelectedIds(props.collectionState.state);
  const disabled = !props.isSelectAll && allSelectedIds.length === 0;

  function handleClick(e) {
    e.preventDefault();
    e.stopPropagation();

    // actions.startPromote(allSelectedIds, { isSelectAll: props.isSelectAll });
  }

  return (
    <Button
      className="ml-2"
      color="primary"
      disabled={disabled}
      onClick={handleClick}
      size="sm"
      type="button"
    >
      {props.actionDefinition.label}
    </Button>
  );
};

export interface SubmitInvoicesActionProps {
  actionDefinition?: IMetadataAction;
  collectionState?: ICollectionStateHook;
  isSelectAll?: boolean;
}

export default SubmitInvoicesAction;
