import { IMetadataEndpoint } from '@broadleaf/admin-components/dist/types/metadata';

function getFetchInvoicesEndpoint(): IMetadataEndpoint {
  return {
    params: {
      q: '${filter.query}',
      cq: '${filter.query_builder}',
      page: '${paging.pageNumber}',
      size: 50
    },
    mapParams: [
      {
        from: 'sort.sort',
        to: 'sort'
      }
    ],
    scope: 'ORDER',
    type: 'GET',
    uri: '/search/order-search'
  };
}

export default {
  getFetchInvoicesEndpoint
};
