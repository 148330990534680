/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import ActionListGrid from '@broadleaf/admin-components/dist/collection/components/ActionListGrid';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import useTracking from '@broadleaf/admin-components/dist/tracking/hooks/useTracking';
import ActionRegistrar from '@broadleaf/admin-components/dist/metadata/services/ActionRegistrar';
import useInvoicesGrid from '../../hooks/useInvoicesGrid';
import OperationUtils from '../../utils/InvoicesOperationUtils';

import messages from './OrderProcessingGrid.messages';
import type { ICommonGridHeader } from '@broadleaf/admin-components/dist/types/common';
import actionComponents from './action-components';
import { getDefaultInvoiceHeaders } from '../../utils/InvoicesGridUtils';

export const OrderProcessingGrid: ReadyToSubmitGridDefinition = () => {
  const { collectionState, getActionListGridProps } = useInvoicesGrid({
    readConfig: OperationUtils.getFetchInvoicesEndpoint(),
    //use this as a catch all for invoices that are ready to submit
    invoiceStatuses: ['ORDER_PROCESSING']
  });

  const actionListGridProps = getActionListGridProps();
  const actionComponents = ActionRegistrar.getActionComponents(
    'View',
    OrderProcessingGrid.VIEW_TYPE
  );
  const actionDefinitions = useOrderProcessingActionDefinitions();
  const headers = useOrderProcessingHeaders();
  const label = useLabel();
  return (
    <ActionListGrid
      {...actionListGridProps}
      actionComponents={actionComponents}
      actionDefinitions={actionDefinitions}
      headers={headers}
      label={label}
      sortable={true}
    />
  );
};

interface ReadyToSubmitGridDefinition extends React.FC {
  VIEW_TYPE: string;
  actionComponents: any;
}

OrderProcessingGrid.VIEW_TYPE = 'ORDER_PROCESSING_GRID';
OrderProcessingGrid.actionComponents = actionComponents;

const useOrderProcessingHeaders = (): Array<ICommonGridHeader> => {
  const formatMessage = useFormatMessage();
  return [...getDefaultInvoiceHeaders(formatMessage)];
};

const useOrderProcessingActionDefinitions = () => {
  const formatMessage = useFormatMessage();
  return [
    {
      attributes: {
        filterKey: 'query_builder'
      },
      label: formatMessage(messages.gridActions.criteriaQuery),
      placement: 'ADVANCED_FILTER',
      type: 'QUERY_BUILDER'
    }
  ];
};

/** Returns a label for the grid with the current sandbox name included. */
const useLabel = () => {
  const tracking = useTracking();
  const formatMessage = useFormatMessage();

  return formatMessage(messages.label);
};

export default OrderProcessingGrid;
